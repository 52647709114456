import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import DatePicker from 'components/shared/DatePicker';
import moment from 'moment';

import Authorize from 'components/common/Authorize';
import authService from 'services/auth/service';
import { userRoleLinks } from 'components/globalInfo/userRoles';
import Select from 'components/shared/Select';
import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import { history } from 'helpers/history';

import { useEquipment } from 'api/v2/equipment';
import { useAddJob } from 'api/v2/jobs';
import { filterOptions } from 'utils/selectUtils';
import multiSelectOptions from 'utils/multiSelectOptions';

import { formatAddJobParams } from './helpers';

const WaterJob = ({
  formState,
  formHandlers,
  selectedCustomer,
  setSelectedCustomer,
  closeAddJobModal,
  userCustomer
}) => {
  const {
    name,
    customerId,
    wellSiteId,
    startDate,
    billingProfileId,
    billingProfiles,
    load_weight: loadWeight,
    certificates,
    sandTicketDesign,
    districtIds,
    directions,
    customerJobId,
    addJobInitData,
    wellSites,
    equipment,
  } = formState;
  const {
    setValue,
    setStartDate,
    clearState,
  } = formHandlers;
  const {
    customers,
    certificatesList,
    sandTicketTypes,
    districts,
    companyArray,
  } = addJobInitData;
  const [additionalOperatorsList, updateOperatorsList] = useState([]);
  const { mutate: saveJob } = useAddJob();
  const { data: allEquipment } = useEquipment();

  const formattedCustomers = customers
    .filter(
      item => item.accessLevel === 'owner' || item.accessLevel === 'shared',
    )
    .map(item => {
      item.label = `${item.id} | ${item.name}`;
      return item;
    });

  const formattedWellSites = useMemo(() =>
    wellSites.map(item => ({
      id: item.id,
      value: item.id,
      label: `${item.id} | ${item.name}`,
      name: item.name
    })),
    [wellSites]
  );
  const billingProfilesList = (billingProfiles ?? []).map(item => ({
    value: item.id,
    label: item.name,
  }));
  const customersList = filterOptions(
    formattedCustomers,
    null,
    null,
    null,
    'label',
  );
  const wellSitesList = filterOptions(
    formattedWellSites,
    null,
    null,
    null,
    'label',
  );
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const user = authService.getUser();
    const formattedParams = formatAddJobParams(formState);

    saveJob(formattedParams, {
      onSuccess: (jobId) => {
        closeAddJobModal();
        setSelectedCustomer(null);
        clearState();
        history.push(`/${userRoleLinks[user.role]}/job-details/${jobId}`);
      }
    });
  };

  useEffect(() => {
    const array = multiSelectOptions(companyArray, 'id', 'name').filter(
      ({ value }) =>
        Number(value.substring(0, value.indexOf('/'))) !== customerId,
    );
    updateOperatorsList(array);
  }, [customerId]);

  useEffect(() => () => {
    setSelectedCustomer(null);
    clearState();
  }, []);

  return (
    <form className="wizard-big wizard" onSubmit={e => handleSubmit(e)}>
      <Modal.Body>
        <div className="content" style={{ padding: '20px 10px' }}>
          <fieldset id="form-p-0" role="tabpanel" className="body current">
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={setValue('name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="jobs_add_water-job_naem_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Choose Customer"
                  onChange={e => {
                    setValue('customerId')(e.value);
                    setValue('customer')(e);
                    setSelectedCustomer(e.value);
                  }}
                  options={customersList}
                  value={customersList.find(item => item.value === customerId)}
                  required
                  isDisabled={userCustomer}
                  testSelector="jobs_add_water-job_customer_select"
                />
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={setValue('districtIds')}
                  options={multiSelectOptions(districts, 'id', 'name')}
                  placeholder="Districts"
                  defaultValue={districtIds}
                  required
                  testSelector="jobs_add_water-job_districts_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Location"
                  onChange={e => setValue('wellSiteId')(e.value)}
                  options={wellSitesList}
                  value={
                    wellSiteId &&
                    wellSitesList.find(item => item.value === wellSiteId)
                  }
                  required
                  isDisabled={!selectedCustomer}
                  testSelector="jobs_add_water-job_wellsite_select"
                />
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Billing Profile"
                  onChange={e => setValue('billingProfileId')(e.value)}
                  value={
                    billingProfileId &&
                    billingProfilesList.find(k => k.value === billingProfileId)
                  }
                  options={billingProfilesList}
                  required
                  isDisabled={!selectedCustomer}
                  testSelector="jobs_add_water-job_billing_profile_select"
                />
              </div>

              <div className="form-group has-feedback col-md-6">
                <DatePicker
                  label="Start Date"
                  required
                  value={startDate}
                  timeFormat="hh:mm A"
                  onChange={e => {
                    setStartDate(e);
                  }}
                  placeholderText="Start Date"
                  data-testid="job-details_add-job_start-date"
                  id="job-details_add-job_start-date"
                  name="job-details_add-job_start-date"
                />
                {(moment().isSameOrAfter(startDate) ||
                  typeof startDate !== 'object') && (
                    <small style={{ color: 'rgba(239, 61, 61, 0.483)' }}>
                      * Incorrect start date
                    </small>
                  )}
              </div>

            </Row>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={setValue('load_weight')}
                  value={loadWeight}
                  label="Load volume, bbl"
                  required
                  min="1"
                  max="60000"
                  testSelector="jobs_add_water-job_load-volume_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={setValue('sandTicketDesign')
                  }
                  options={multiSelectOptions(
                    sandTicketTypes,
                    'sandTicketTypeId',
                    'name',
                  )}
                  placeholder="Ticket Types"
                  defaultValue={sandTicketDesign}
                  required
                  testSelector="jobs_add_water-job_ticket_types_select"
                />
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={setValue('certificates')}
                  options={multiSelectOptions(certificatesList, 'id', 'name')}
                  placeholder="Certificates"
                  required
                  defaultValue={certificates}
                  testSelector="jobs_add_water-job_certificates_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={setValue('equipment')}
                  options={multiSelectOptions(allEquipment, 'id', 'title')}
                  placeholder="Equipment"
                  value={equipment}
                  required
                  testSelector="jobs_add_equipment_select"
                />
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={setValue('customerJobId')}
                  value={customerJobId}
                  label="Customer job ID"
                  testSelector="jobs_add_water-job_customer-job-id_input"
                />
              </div>
              <div className="col-md-6">
                <div className="form-group has-feedback ">
                  <Authorize
                    {...{
                      access: ['JOB_MANAGER', 'CUSTOMER'],
                    }}>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={setValue('operatorList')}
                      options={additionalOperatorsList}
                      placeholder="Additional Operators"
                      testSelector="jobs_add_water-job_additional_operators_select"
                    />
                  </Authorize>
                </div>
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="col-md-6">
                <div className="form-group has-feedback ">
                  <Textarea
                    label="Directions"
                    onChange={setValue('directions')}
                    value={directions}
                    required
                    testSelector="jobs_add_water-job_directions_input"
                  />
                </div>
              </div>
            </Row>
            {/* <Row className="no-outer-gutter">
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    label="Send completion code to driver"
                    testSelector="jobs-list_add-job_water-job_send-code-to-driver_input"
                    isChecked={sendCompletionCodeToDriver}
                    onChange={e =>
                      setValue(e, 'sendCompletionCodeToDriver')
                    }
                  />
                </div>
              </Row> */}
          </fieldset>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddJobModal}
          colour="white"
          testSelector="jobs-list_add-modal_water-job_close_btn">
          Close
        </Button>
        <Button
          type="submit"
          disabled={moment().isSameOrAfter(startDate)}
          testSelector="jobs-list_add-modal_water-job_save_btn">
          Save
        </Button>
      </Modal.Footer>
    </form>
  );
};
export default WaterJob;
